
.navbar-brand {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    margin: auto;
}
.navbar-toggle {
    z-index:3;
		border:0
}


.navbar{
	margin-bottom:0;
	border:none !important;
	border-radius:0;
}



/* Annimate the hamburger X menu */
.navbar-toggle {
  border: none;
  background: transparent !important;
}
.navbar-toggle:hover {
  background: transparent !important;
}
.navbar-toggle .icon-bar {
  width: 22px;
  transition: all 0.2s;
}
.navbar-toggle .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.navbar-toggle .middle-bar {
  opacity: 0;
}
.navbar-toggle .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
.navbar-toggle.collapsed .top-bar {
  transform: rotate(0);
}
.navbar-toggle.collapsed .middle-bar {
  opacity: 1;
}
.navbar-toggle.collapsed .bottom-bar {
  transform: rotate(0);
}



/* Overide - Initiate hamburger menu early */
@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
  		display:block !important;
	}
}