//
// Carousel
// --------------------------------------------------


.carousel-caption { // position text vert centered
	height:100%;
	>div{
		position:absolute;
		top:48%;
		transform: translateY(-50%);
		width:100%;
	}
}
.carousel-inner>.item>a>img, 
.carousel-inner>.item>img {
	margin-left:auto;
	margin-right:auto
}